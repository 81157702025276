exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-foreningar-js": () => import("./../../../src/pages/foreningar.js" /* webpackChunkName: "component---src-pages-foreningar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-regler-2021-js": () => import("./../../../src/pages/regler_2021.js" /* webpackChunkName: "component---src-pages-regler-2021-js" */),
  "component---src-pages-regler-js": () => import("./../../../src/pages/regler.js" /* webpackChunkName: "component---src-pages-regler-js" */),
  "component---src-pages-texter-js": () => import("./../../../src/pages/texter.js" /* webpackChunkName: "component---src-pages-texter-js" */)
}

